import {
    Container,
    Row,
    Col,
    Card
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import SourceFlowForm from '@sourceflow-uk/sourceflowform';
import EditableText from "@/components/EditableText";
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import sourceflowEnums from "@/sourceflow.enums"; 

export default function Form({
    className,
    formId,
    successPageUrl,
    content_1,
    pathPrefix,
    salesforce_embed
}) {

    const router = useRouter();

    const url = `https://go.mailtwrecruitment.com/l/1042361/2024-06-24/37jcfq`;
    const handleSalesforceSubmit = (e) => {
        const formBody = new URLSearchParams();

        // Append UTM parameters
        Object.keys(utmParams).forEach(key => {
            formBody.append(key, utmParams[key]);
        });

        Object.keys(e.data).forEach(key => {
            formBody.append(`data[${key}]`, e.data[key]);
        });

        fetch(url, {
            method: 'POST',
            mode: "no-cors",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: formBody.toString()
        })

            .then(res => {
                // Assuming you want to redirect on successful completion
                window.location.href = successPageUrl;

                // if (res.ok) {
                //     window.location.href = successPageUrl;
                // } else {
                //     // Handle error scenarios if needed
                //     console.error('Failed to submit form:', res.status, res.statusText);
                // }
            })
            .catch(error => {
                // console.error('Error submitting form:', error);
                // Handle fetch errors if needed
            });

        // window.location.href = successPageUrl;
    };


    const [utmParams, setUtmParams] = useState({});

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlParams.entries());
        if (params.utm_source) {
            setUtmParams(params);
        }
    }, []);


    // useEffect(() => {
    //     // Optional: Handle form submission directly on mount
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const params = Object.fromEntries(urlParams.entries());
    //     if (params.utm_source) {
    //         handleSalesforceSubmit({ data: params });
    //     }
    // }, []);

    // console.log(router.asPath);

    useEffect(() => {
        if (formId === sourceflowEnums.forms.get_started) {
            const addClassToButton = () => {
                const formElement = document.querySelector(`#sourceflow-form-${formId}`);
                if (formElement) {
                    const button = formElement.querySelector('button[type="submit"]');
                    if (button) {
                        button.classList.add('get-started-btn-submit');
                    }
                }
            };

            addClassToButton();
        }
    }, [formId]);

    return (
        formId && (
            <div className={clsx(className, styles.root)}>
                {content_1 && (
                    <EditableText
                        content={content_1}
                        path={`${pathPrefix}.content_1`}
                        global={global}
                        tag={`div`}
                        className={`mb-4`}
                    />
                )}
                <div className="d-none" id="page-submitted-on" data-event={`${router.asPath}`} />
                {salesforce_embed ?
                    <>
                        <div
                            id="utm_source"
                            data-utm_source={utmParams.utm_source || ''}
                        />
                        <div
                            id="utm_medium"
                            data-utm_medium={utmParams.utm_medium || ''}
                        />
                        <div
                            id="utm_campaign"
                            data-utm_campaign={utmParams.utm_campaign || ''}
                        />
                        <SourceFlowForm
                            formId={formId}
                            // onSubmitDone={() => (window.location.href = successPageUrl)}
                            onSubmitDone={handleSalesforceSubmit}
                        />
                    </> :
                    <SourceFlowForm
                        formId={formId}
                        onSubmitDone={() => (window.location.href = successPageUrl)}
                    />}
            </div>
        )
    )
}
Form.defaultProps = {
    className: "",
    successPageUrl: "",
    pathPrefix: ""
};

Form.propTypes = {
    className: PropTypes.string,
    successPageUrl: PropTypes.string,
    pathPrefix: PropTypes.string,
};